<template>
  <v-form v-model="valid">
    <v-container class="pa-0" fluid>
      <v-row dense>
        <v-col cols="12">
          <v-textarea
            v-model="localFormData.DETAILS"
            :rules="[rules.required]"
            :counter="1500"
            rows="5"
            :label="$t('FORMS.VARIOUS_CONCERNS.DETAILS') + ' *'"
            hide-details
            solo
            :append-icon="errors.details ? 'mdi-alert-circle-outline' : null"
            @update:error="errors = { ...errors, details: $event }"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row dense text-center>
        <v-col cols="12">
          <slot name="button" :valid="valid" />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <slot name="description" />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import Rules from "@/app/modules/_global/classes/Rules";

export default {
  name: "VariousConcernsForm",

  model: {
    prop: "formData"
  },

  props: {
    formData: {
      required: true,
      type: Object
    }
  },

  data: () => ({
    localFormData: null,

    valid: true,
    errors: {},

    rules: {
      required: Rules.required
    }
  }),

  watch: {
    localFormData: {
      deep: true,
      handler: "setFormData"
    }
  },

  created() {
    this.localFormData = Object.assign({}, this.formData);
  },

  methods: {
    setFormData() {
      this.$emit("input", this.localFormData);
    }
  }
};
</script>
